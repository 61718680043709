
import React from "react";
import {
Route,
Routes,
HashRouter,
} from "react-router-dom";


// Import views
// import Overview from './views/overview';
// import Account from './views/account';
// import Admin from './views/admin';
// import Data from './views/data';
// import Settings from './views/settings';
// import Pos from './views/Pos';

// import site componets
// import Sidebar from "./componets/dash/sidebar";
// import Logout from './views/Logout';
// import sidebarTab from "./componets/sidebar/sidebar-tab";

import './assets/css/global.css'
import './assets/css/main.css';
import './assets/css/auth.css';
import Login from "./views/auth/login";
import Register from "./views/auth/register";
import Auth from "./views/auth/auth";
import LandingPage from "./views/landing/landing";
import Dashboard from "./views/dashboard/dashboard";
import NoticePage from "./views/noticePage";
import DashSettings from "./componets/dashboard/settings/settings";

import SiteBackground from "./componets/site/suteBackground";
import AccountSettings from "./componets/dashboard/settings/accountSettings";
import AppearanceSettings from "./componets/dashboard/settings/appearanceSettings";
import SecuritySettings from "./componets/dashboard/settings/securitySettings";
import DafrigSettings from "./componets/dashboard/settings/dafrigSettings";
import DashProfile from "./componets/dashboard/profile/profile";
import AdminPage from "./views/dashboard/admin";
import AdminMount from "./componets/dashboard/admin/adminMount";
import DashCdn from "./componets/dashboard/cdn/dashCdn";

function App() {

return (
  <HashRouter>
    <div className="app" id="app">
      {/* {console.clear()} */}

      <SiteBackground />


      <Routes>

        <Route exact path="/" element={<LandingPage />}/>

        <Route path='auth' element={<Auth />}>
          <Route index element={<Login />}/>
          <Route path='login' element={<Login />}/>
          <Route path='register' element={<Register />}/>
          <Route path='*' element={<h1>No Routes</h1>}/>
        </Route>
        
        <Route path='dashboard' element={<Dashboard />}>
          <Route exact index element={"BROEEE"} />
          
          <Route path="overview" element={"BROEEE"} />
          <Route path="cdn" element={<DashCdn />} />


          <Route path="settings" element={<DashSettings />}>
            <Route index element={<AccountSettings />}/>

            <Route path="account" element={<AccountSettings />}/>
            <Route path="appearance" element={<AppearanceSettings />}/>
            <Route path="security" element={<SecuritySettings />}/>
            <Route path="dafrig" element={<DafrigSettings />}/>

            <Route path='*' element={<NoticePage title={'No Routes'} desc={'Welcome to the void'} redirect={false}/>}/>
          </Route> 

          <Route path="profile" element={<DashProfile />} />
          {/* <Route path="admin" element={<AdminPage />} /> */}

          <Route path='admin' element={<AdminMount />}>
            <Route path="managment" element={<AdminPage />} />
          </Route>

        </Route>  



        

        <Route path='*' element={<NoticePage title={'No Routes'} desc={'Welcome to the void'} redirect={true}/>}/>
      </Routes>




    </div>


  </HashRouter>
);
}

export default App;