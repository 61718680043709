import React, { Component } from 'react'
import { ClientManager } from '../../../assets/js/clientSettings';
import DashSettingsOption from './settingOption'

export default class DafrigSettings extends Component {

  Client = new ClientManager();

  render() {
    return (
      <div className="dash-settings-tab-panel">

        <h1 className='dash-settings-header'>Broe dafrig?</h1>


        <DashSettingsOption 
        title='Generic setting'
        description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam adipisci consequatur consequuntur quisquam.'
        type={'number'}
        onChange={this.randomLog}
        />
        <DashSettingsOption 
        title='Generic setting'
        description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam adipisci consequatur consequuntur quisquam.'
        type={'range'}
        onChange={this.randomLog}

        />
        <DashSettingsOption 
        title='Generic setting'
        description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam adipisci consequatur consequuntur quisquam.'
        type={'text'}
        placeholder={"Broo"}
        onChange={console.log("bro")}
        />
        <DashSettingsOption 
        title='Generic setting'
        description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam adipisci consequatur consequuntur quisquam.'
        type={'drop'}
        defaultValue={'ASD'}
        options={[
            {value: 'dghdfgh', label: 'asdasd'},
            {value: 'ghdghAA', label: 'asdasd'},
            {value: 'AdfghdA', label: 'maini'},
            {value: 'AdgfhA', label: 'asdasd'},
            {value: 'ASD', label: 'asd'},
        ]}
        onChange={console.log("bro")}
        />
        <DashSettingsOption 
        title='Generic setting'
        description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam adipisci consequatur consequuntur quisquam.'
        type={'button'}
        label={"Lmao xD?"}
        onClick={console.log("bro")}
        />

        <DashSettingsOption 
        title='Generic setting'
        description='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam adipisci consequatur consequuntur quisquam.'
        type={'switch'}
        defaultValue={true}
        onChange={console.log("bro")}
        />
        {/* <DashSettingsOption /> */}
        {/* <DashSettingsOption /> */}
        {/* <DashSettingsOption /> */}
        {/* <DashSettingsOption /> */}
        {/* <DashSettingsOption /> */}

      </div>
    )
  }
}
