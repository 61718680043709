import React, { Component } from 'react'
import { ClientManager } from '../../../assets/js/clientSettings';
import store from '../../../store/store';
import userSlice from '../../../store/userSlice';
import DashSettingsOption from './settingOption'
import { connect } from 'react-redux';
import { API } from '../../../services/api';
import LoadingPlaceholder from '../loadingPlaceholder';
import Timestamp from '../timestamp';

class SecuritySettings extends Component {

  Client = new ClientManager();

  componentDidMount() {
    this.updateLoginHistory();  // Init Login History
  }
  updateLoginHistory() {

    store.dispatch(userSlice.actions.clearLoginHistory())

    API.request('GET', '/users/@me/logins').then((response) => {
      console.log(response);
      store.dispatch(userSlice.actions.updateLoginHistory(response))
    }, (error) => {
      console.log("req err => ", error)
    })
  }
  render() {
    return (
      <div className="dash-settings-tab-panel">

        <h1 className='dash-settings-header'>Security</h1>

        
        <DashSettingsOption 
          title='Logout'
          description='Clears token from browsers localStorage'
          type={'button'}
          label={"Logout"}
          onClick = {
            () => {
              store.dispatch(userSlice.actions.createConfirmationPopup({
                title: "Logout?",
                message: "Are you sure you want to logout?",
                actions: [{
                  name: "Logout",
                  action: () => {
                    console.log("Logout confirmed...");
                    this.Client.logout();
                  },
                  dispose: true, // delete dialog after
                  important: true
                }],
              }, ))
            }
          }
      />

{/*         
        <DashSettingsOption 
          title='Can i has secure?'
          description='I can has secure'
        /> */}

        <DashSettingsOption 
          title='Login Activity'
          description='Where your account was logged in'
          type={'button'}
          label={"Refresh History"}
          onClick={ () => {
            this.updateLoginHistory();
          }}
          underneath={
            this.props.loginHistory.length !== 0 ?
            <div>
              {
              this.props.loginHistory.slice(0).reverse().map((login) => {
                // console.log(`${login.agent} - ${login.ip} - ${login._id}`);
                return <span className='dash-settings-option-underneath-list-item ff-mono'>
                  User Agent: {login.user_agent} <br /> 
                  IP: <span className='hidden-text'>{login.ip}</span> <br />
                  Login ID: {login.uuid} <br />
                  Time: <Timestamp epoch={login.timestamp} />
                  </span>
              })
            }

            </div>
              : <LoadingPlaceholder />
          }
        />

        


      </div>
    )
  }
}


function mapStateToProps(state, ownProps) {
  return {
      loginHistory: state.loginHistory,

  };
}

export default connect(mapStateToProps)(SecuritySettings);