import React, { Component } from 'react'
import { Tabs } from 'react-tabs';
import  { NavLink, Outlet } from 'react-router-dom'
import { ClientManager } from '../../../assets/js/clientSettings';

import './../../../assets/css/dashboard.css'

export default class DashSettings extends Component {
    randomLog() {
        console.log("BROOOSKIIS");
    }



    Client = new ClientManager();

    render() {

        return (
            <div className='dash-page narrow'>
                <Tabs className='dash-settings-page'>
                    <div className='dash-settings-nav-tab-list'>
                        <NavLink className="dash-settings-nav-tab" to='/dashboard/settings/account'>Account</NavLink>
                        <NavLink className="dash-settings-nav-tab" to='/dashboard/settings/appearance'>Appearance</NavLink>
                        <NavLink className="dash-settings-nav-tab" to='/dashboard/settings/security'>Security</NavLink>
                        <NavLink className="dash-settings-nav-tab" to='/dashboard/settings/dafrig'>Dafrig</NavLink>
                    </div>


                    <Outlet />


                </Tabs>
            </div>
        )
    }
}
