import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ClientManager } from '../../../assets/js/clientSettings';
import DashSettingsOption from '../settings/settingOption';
import UserProfile from './userProfile';

class DashProfile extends Component {
    Client = new ClientManager();

    componentDidMount() {
        this.Client.settings.reload.userdata(); // Init user data
    }
    render() {
        return (
            <div className='dash-page narrower'>


            <UserProfile 
                user={this.props.user ? this.props.user : ''}
                centered={true}
            />


            <h1 className='dash-settings-header'> </h1>


                

            <DashSettingsOption 
              title='Avatar URL'
              description='Avatar url.'
              type={'text'}
              placeholder={"Avatar URL"}
              largerInput={true}
              defaultValue={this.props.user.avatar ? this.props.user.avatar : ''}
              onChange={this.Client.settings.set.avatar}
            />
            <DashSettingsOption 
              title='Banner URL'
              description='Banner url.'
              type={'text'}
              placeholder={"Banner URL"}
              largerInput={true}
              defaultValue={this.props.user.banner ? this.props.user.banner : ''}
              onChange={this.Client.settings.set.banner}

            />

            <h1 className='dash-settings-header'> </h1>

            <DashSettingsOption 
                title='Trusted URL Hosts'
                description='Hosts you can use for your banner/avatar'
                type={''}
                underneath={
                <div>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>cdn.discordapp.com</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>media.discordapp.net</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>i.imgur.com</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>i.redd.it</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>i.gyazo.com</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>i.reddituploads.com</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>waifu.rentals</span>
                </div>
                }
            />

            <DashSettingsOption 
                title='Trusted Image Formats'
                description='Allowed image file extensions'
                type={''}
                underneath={
                <div>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>png</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>gif</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>jpg</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>jpeg</span>
                    <span className='dash-settings-option-underneath-list-item ff-mono'>webp</span>

                </div>
                }
            />











            {this.props.user.username ? this.props.username : ''}

            {console.log(`SDFSDF>`)}
            {console.log(this.props.user.avatar)}

            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
  
    };
  }
  
export default connect(mapStateToProps)(DashProfile);