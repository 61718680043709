

export class DashManager {
    // public api: API = new API();
	// public state: AppState = new AppState(this.api);
	// public shortcutManager: ShortcutManager = new ShortcutManager(this.state.window);
	// public soundManager: SoundManager = new SoundManager(this.state);
	// public Discord: Discord = new Discord(this.state);

	constructor() {
		this.initDashboard();
	}

	initDashboard() {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') === 'unset') {
            //ur not authed, get outtaaa here
            window.location.href = "/#/auth/login" // slap the user at the login page
            // theres prolly a better way to redirect users :/
        }
        
		// this.state.users.fetchMe();
		// this.state.machines.fetchMachines().then(() => this.api.createWebsocketConnection(this.state));
	}
}