import { Component } from 'react'

import './../../assets/css/main.css'
import './../../assets/css/dashboard.css'
import DashNavItem from './dashNavItem'

import { ClientManager } from '../../assets/js/clientSettings';
import { connect } from 'react-redux';
import store from '../../store/store';
import userSlice from '../../store/userSlice';


class DashNav extends Component {

    Client = new ClientManager();
    
  render() {
    return (
        <div className='dash-nav-container'>
            <div className='dash-nav-section top'>

                <DashNavItem 
                    title="Tehbb.xyz"
                    icon="/assets/icons/TehbbIcon.ico"
                    link="/"
                    isHeader={true}
                />

                <div className='dash-nav-seperator' />


                <DashNavItem 
                    title="Home"
                    icon="/assets/icons/Board.svg"
                    link="/dashboard/overview"
                />

                <DashNavItem 
                    title="T-CDN"
                    icon="/assets/icons/OfficeApps.svg"
                    link="/dashboard/cdn"
                />



                {this.props.user.is_admin ? 
                <DashNavItem 
                    title="Admin"
                    icon="/assets/icons/Shield.svg"
                    link="/dashboard/admin/managment"
                /> : ''}

            </div>
            <div className='dash-nav-section bottom'>
                <DashNavItem 
                    title="Settings"
                    icon="/assets/icons/Settings.svg"
                    link="/dashboard/settings"
                />

                <DashNavItem 
                    title="Profile"
                    icon= {this.props.user.avatar ? `${this.props.user.avatar}` : "/assets/icons/Person.svg"}
                    link="/dashboard/profile"
                    roundedIcon={true}
                    noInvert={true}
                />

                <div className='dash-nav-seperator' />
                
                <DashNavItem 
                    title="Logout"
                    icon="/assets/icons/DoorArrowLeft.svg"
                    link="/logout"
                    type='button'
                    action={
                        () => {
                        store.dispatch(userSlice.actions.createConfirmationPopup({
                            title: "Logout?",
                            message: "Are you sure you want to logout?",
                            actions: [{
                                name: "Logout",
                                action: () => {
                                console.log("Logout confirmed...");
                                this.Client.logout();
                                },
                                dispose: true, // delete dialog after
                                important: true
                            }],
                            }, ))
                        }
                    }
                />

            </div>
        </div>

    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
  
    };
  }
  
export default connect(mapStateToProps)(DashNav);