import { API } from "../../services/api";
import store from "../../store/store";
import userSlice from "../../store/userSlice";



export class ClientManager {

    
    logout() {
        localStorage.setItem('token', 'unset');
        require('./../../store/store'); // redefine store
        window.location.href = "/#/auth/login"; // slap the user at the login page
        
    }
    async generate_cdn_token() {


        return await API.request('GET', '/c/token').then((response) => {
            console.log(response);
            console.log(response.token);
            if (!response.error) {
                return response;
                return JSON.parse(response);
            } else {
                store.dispatch(userSlice.actions.createNotification( // notify user about deletion
                    {
                    title: "Error generating token:",
                    message: `${response.error}}`,
                    }
                ));
            }
        });
    }


    settings = {
        async clearStorage() {
            localStorage.clear(); // setting die
    
            // this.updateSetting.reloadAppearanceSettings(); // reload dead settings
            // this.updateSetting.reloadAppearanceSettings();
            this.reload.userdata();
            this.reload.appearance();
    
            window.location.href = "/#/auth/login"; // slap the user at the login page
        },
        set: {
            wallpaperUrl: async event => {
      
                console.log(`Setting wallpaper > ${event.target.value}`);
                localStorage.setItem('WallpaperUrl', event.target.value);
    
                this.settings.reload.appearance();
            },
            wallpaperOpacity: async event => {
                let root = document.getElementById('app');
      
                console.log(`Opac > ${event.target.value}`);
                localStorage.setItem('WallpaperOpacity', event.target.value);
                root.style.setProperty('--background-wallpaper-opacity', `${event.target.value}`);
            },

            wallpaperBlur: async event => {
                let root = document.getElementById('app');
      
                console.log(`Blur > ${event.target.value}`);
                localStorage.setItem('WallpaperBlur', event.target.value);
                root.style.setProperty('--background-wallpaper-blur', `${event.target.value}px`);
            },
            uiOpacity: async event => {
                let root = document.getElementById('app');
      
                console.log(`Opac > ${event.target.value}`);
                localStorage.setItem('UiOpacity', event.target.value);
                root.style.setProperty('--dash-ui-opacity', `${event.target.value/100}`);
            },
            uiBlur: async event => {
                let root = document.getElementById('app');
      
                console.log(`Blur > ${event.target.value}`);
                localStorage.setItem('UiBlur', event.target.value);
                root.style.setProperty('--dash-ui-blur', `${event.target.value}px`);
            },
            theme: async event => {
                let root = document.getElementById('app');

                var oldTheme = localStorage.getItem('SiteTheme');
                
                // console.log('brrrrr themVV');
                // console.log(event.target.value);
                localStorage.setItem('SiteTheme', event.target.value);
    
                // console.log(`oldtheme: ${oldTheme}`);

                if (oldTheme === 'unset' || oldTheme === '' || !oldTheme) {
                    root.classList.add(`${localStorage.getItem('SiteTheme')}`); // add theme
                } else { // there is a theme stored in localStorage
                    root.classList.remove(`${oldTheme}`); // replace theme
                    root.classList.add(`${localStorage.getItem('SiteTheme')}`); // add theme
                }

                this.settings.reload.appearance();
            },
            banner: async event => {
                console.log(event.target.value);

                store.dispatch(userSlice.actions.clearLoginHistory())

                API.request('PATCH', '/users/@banner', {
                    url: `${event.target.value}`
                }).then((response) => {
                  console.log(response);
                  if (!response.error) {
                    store.dispatch(userSlice.actions.updateUserdata(response))
                  }
                }, (error) => {
                  console.log("req err => ", error)
                })
            },
            avatar: async event => {
                console.log(event.target.value);

                store.dispatch(userSlice.actions.clearLoginHistory())

                API.request('PATCH', '/users/@avatar', {
                    url: `${event.target.value}`
                }).then((response) => {
                  console.log(response);
                  if (!response.error) {
                    store.dispatch(userSlice.actions.updateUserdata(response))
                  }
                }, (error) => {
                  console.log("req err => ", error)
                })
            }


        },
        reload: {
            userdata() {
                API.request('GET', '/users/@me/').then((response) => {
                    console.log(response);
                    store.dispatch(userSlice.actions.updateUserdata(response))
                }, (error) => {
                    console.log("req err => ", error)
                })
            },
            appearance() {
                console.log('Reloading appearance...');
                let root = document.getElementById('app');
        
        
                // Wallpaper url:
                let storedWallpaperUrl = localStorage.getItem('WallpaperUrl');
                if (storedWallpaperUrl === 'unset' || !storedWallpaperUrl) {
                    console.log('Wallpaper not found in localStorage');
                    root.style.setProperty('--background-wallpaper-url', ` var(--background-wallpaper-url-default)`);
                } else {
                    console.log('Wallpaper found in localStorage');
                    root.style.setProperty('--background-wallpaper-url', `url(${storedWallpaperUrl})`);
                }


                // Wallpaper Opacity:
                let storedWallpaperOpacity = localStorage.getItem('WallpaperOpacity');
                if (storedWallpaperOpacity === 'unset' || !storedWallpaperOpacity) {
                    root.style.setProperty('--background-wallpaper-opacity', ` var(--background-wallpaper-opacity-default)`);
                } else {
                    root.style.setProperty('--background-wallpaper-opacity', `${storedWallpaperOpacity}`);
                }

                // Wallpaper Blur:
                let storedWallpaperBlur = localStorage.getItem('WallpaperBlur');
                if (storedWallpaperBlur === 'unset' || !storedWallpaperBlur) {
                    root.style.setProperty('--background-wallpaper-blur', ` var(--background-wallpaper-blur-default)`);
                } else {
                    root.style.setProperty('--background-wallpaper-blur', `${storedWallpaperBlur}px`);
                }


                // Wallpaper url:
                let storedTheme = localStorage.getItem('SiteTheme');


                
                // Ui Opacity:
                let storedUiOpacity = localStorage.getItem('UiOpacity');
                if (storedUiOpacity === 'unset' || !storedUiOpacity) {
                    root.style.setProperty('--dash-ui-opacity', ` var(--dash-ui-opacity-default)`);
                } else {
                    root.style.setProperty('--dash-ui-opacity', `${storedUiOpacity/100}`);
                }
                // Ui Blur:
                let storedUiBlur = localStorage.getItem('WallpaperBlur');
                if (storedUiBlur === 'unset' || !storedUiBlur) {
                    root.style.setProperty('--dash-ui-blur', ` var(--dash-ui-blur-default)`);
                } else {
                    root.style.setProperty('--dash-ui-blur', `${storedUiBlur}px`);
                }
                


                // console.log(`contains? ${root.classList.contains(`${localStorage.getItem('SiteTheme')}`)}`);
                if (storedTheme !== 'unset' && storedTheme) {
                    // root.classList.add(`${localStorage.getItem('SiteTheme')}`);
                    
                    if (!root.classList.contains(`${localStorage.getItem('SiteTheme')}`)) {
                        root.classList.add(`${localStorage.getItem('SiteTheme')}`)
                    }

                }
            }
        }


    }
}