import React, { Component } from 'react'
import { ClientManager } from '../../../assets/js/clientSettings';
import DashSettingsOption from './settingOption'

export default class AppearanceSettings extends Component {

  Client = new ClientManager();

  render() {
    return (
      <div className="dash-settings-tab-panel">
        
        <h1 className='dash-settings-header'>Appearance</h1>

              <DashSettingsOption 
              title='Color Theme'
              description="Set the dashboard's color theme (some themes are WIP)"
              type={'drop'}
              defaultValue={localStorage.getItem('SiteTheme')}
              options={[
                  {value: 'theme-dark', label: 'Dark'},
                  {value: 'theme-nord', label: 'Nord'},
                  {value: 'theme-opera', label: 'Opera'},
                  {value: 'theme-mono', label: 'Mono'},
                  {value: 'theme-light', label: 'Light'},
              ]}
              onChange={this.Client.settings.set.theme}
              />
              <DashSettingsOption 
              title='Wallpaper'
              description='Set the sites background wallpaper.'
              type={'text'}
              placeholder={"Image URL"}
              defaultValue={localStorage.getItem('WallpaperUrl')}
              onChange={this.Client.settings.set.wallpaperUrl}
              />


              <DashSettingsOption 
              title='Wallpaper'
              description='Set the background wallpaper of the site (For this browser only)'
              type={'drop'}
              defaultValue={localStorage.getItem('WallpaperUrl')}
              options={[
                  {value: 'unset', label: 'Default'},
                  // {value: 'https://wallpaperaccess.com/download/aesthetic-817',           label: 'Fence'},
                  // {value: 'https://wallpaperaccess.com/download/4k-dual-monitor-128644' , label: 'Lake Tree'},
                  {value: 'https://wallpaperaccess.com/download/4k-dual-monitor-756721' , label: 'Mountain Sunset'},
                  {value: 'https://wallpaperaccess.com/download/aesthetic-811',           label: 'Damaged Goods'},
                  {value: 'https://wallpaperaccess.com/download/4k-dual-monitor-756745' , label: 'Nighttime City'},
                  {value: 'https://wallpaperaccess.com/download/4k-dual-screen-756634' ,  label: 'Nighttime City 2'},
                  {value: '/assets/img/wallpapers/Rooftop%20Chillin.jpg' ,  label: 'Chillin on a rooftop'},
                  {value: 'https://4kwallpapers.com/images/wallpapers/blue-mountains-foggy-mountain-range-landscape-scenery-5k-6016x3384-5939.jpg' ,  label: 'Foggy Mtns'},
                  {value: 'https://wallpaperaccess.com/download/aesthetic-848' ,  label: 'Sakura'},

                  
              ]}
              onChange={this.Client.settings.set.wallpaperUrl}
              />




              <DashSettingsOption 
              title='Wallpaper Opacity'
              description='Set the opacity of the wallpaper'
              type={'range'}
              onChange={this.Client.settings.set.wallpaperOpacity}
              defaultValue={
                  localStorage.getItem('WallpaperOpacity') ? 
                      localStorage.getItem('WallpaperOpacity') 
                      : 100 // default value
              }
              />
              <DashSettingsOption 
              title='Wallpaper Blur'
              description='Set the background blur'
              type={'range'}
              onChange={this.Client.settings.set.wallpaperBlur}
              defaultValue={
                  localStorage.getItem('WallpaperBlur') ? 
                      localStorage.getItem('WallpaperBlur') 
                      : 10 // default value
              }
              />

              <h1 className='dash-settings-header'>User Interface</h1>

              <DashSettingsOption 
              title='Ui Opacity'
              description='Set the opacity of the dashboard ui'
              type={'range'}
              onChange={this.Client.settings.set.uiOpacity}
              defaultValue={
                  localStorage.getItem('UiOpacity') ? 
                      localStorage.getItem('UiOpacity') 
                      : 85 // default value
              }
              />

              <DashSettingsOption 
              title='Ui Blur'
              description='Set the background blur of the dashboard ui'
              type={'range'}
              onChange={this.Client.settings.set.uiBlur}
              defaultValue={
                  localStorage.getItem('UiBlur') ? 
                      localStorage.getItem('UiBlur') 
                      : 100 // default value
              }
              />
      </div>
    )
  }
}
