import React, { Component } from 'react'
import { connect } from 'react-redux';

import './../../assets/css/dashboard.css';
import './../../assets/css/dash/notification.css';
import './../../assets/css/theme.css';
import store from '../../store/store';
import userSlice from '../../store/userSlice';

class Notification extends Component {

  disposeTime = 1500;

  componentDidMount() {

  }

  deleteMsgSelf(index) {
    store.dispatch(userSlice.actions.removeNotification(index))

  }


  render() {
    return (
      <div className={`
        dash-notification-wrapper 
        ${this.props.popups.notification.length === 0 ? 'hidden' : ''}
      `}>
        <div className='dash-notification-container'>
        {
          this.props.popups.notification.slice(0).map((message, index) => {

            return <div className={`dash-notification ${message.dispose ? 'dispose' : ''}`}>
                  <div className='dash-notification-close'
                  onClick={() => {
                    this.deleteMsgSelf();
                  }}>
                    Close
                  </div>
                  <div className='dash-notification-content'>
                    <h1 className='dash-notification-title'>{message.title}</h1>
                    <p className='dash-notification-message'>{message.message}</p>
                  </div>

              </div>
          })
          
        }   
        </div>


      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
      popups: state.popups,

  };
}

export default connect(mapStateToProps)(Notification);