import React, { Component } from 'react'
import './../../../assets/css/dash/profile.css'
import UserBasicProfile from './userBasicProfile'

export default class UserProfile extends Component {
  render() {
    return (
        <div className={`dash-profile-container ${this.props.centered ? 'center' : ''}`}>
            <div 
                className='dash-profile-banner round-top'
                style={
                    {backgroundImage: `url(${this.props.user.banner})`}
                }
            >
            </div>
            <div className='dash-profile-normal-basic-container'>
                <UserBasicProfile 
                    user={this.props.user ? this.props.user : {}}
                    flatTop={true}
                    backgroundImage={this.props.user.banner}
                />
            </div>

            
        </div>
    )
  }
}
