import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import './../../assets/css/main.css'
import './../../assets/css/nav.css'
export default class NavBar extends Component {
  render() {
    return (
        <div className='nav-top-wrapper'>
            <div className='nav-top-inner'>

                <div className='nav-top-section left'>
                    <h1 className='nav-top-header'>TEHBB.xyz</h1>
                </div>

                <div className='nav-top-section right'>
                    <a className='nav-top-link' href='/mail'>Webmail</a>
                    <Link className='nav-top-link' to="/auth/register">Register</Link>
                    <Link className='nav-top-link' to="/auth/login">Login</Link>
                    <Link className='nav-top-link' to="/">Home</Link>
                </div>
            </div>

        </div>
    )
  }
}
