import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class LoadingPlaceholder extends Component {
  static propTypes = {
    height: PropTypes.string
  }

  render() {
    return (
      <div className='dash-placeholder-loading-wrapper'>
        <div className='dash-placeholder-loading-inner'>
            <div className='dash-loading'></div>
        </div>
      </div>
    )
  }
}
