import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  // Route,
  NavLink,
  // HashRouter
} from "react-router-dom";



export default class DashNavItem extends Component {
  render() {
    if (this.props.type === 'button') {
      return (
        <div
        onClick={this.props.action}
        className={`
          dash-nav-item ${this.props.isHeader ? 'header' : ''}`}>
          <div className={`dash-nav-item-icon ${this.props.isHeader ? 'spinny' : ''}`} style={
            {
              // item-specific styles
              backgroundImage: `url(${this.props.icon})`
            }
          }></div>
          
          <div className={`
            dash-nav-item-label 
            ${this.props.isHeader ? 'site-logo-text' : ''}
          `}>
              {this.props.title}
          </div>
  
        </div>
      );
    } else {
      return (
        <NavLink
        to={this.props.link}
        className={`
          dash-nav-item ${this.props.isHeader ? 'header' : ''}`}>
          <div className={`dash-nav-item-icon ${this.props.noInvert ? 'no-invert' : ''} ${this.props.isHeader ? 'spinny' : ''} ${this.props.roundedIcon ? 'rounded' : ''}`} style={
            {
              // item-specific styles
              backgroundImage: `url(${this.props.icon})`
            }
          }></div>
          
          <div className={`
            dash-nav-item-label 
            ${this.props.isHeader ? 'site-logo-text' : ''}
          `}>
              {this.props.title}
          </div>
  
        </NavLink>
      );
    }

  }
}

DashNavItem.propTypes = {
  link: PropTypes.string, 
  icon: PropTypes.string, 
  title: PropTypes.string, 
  // active: PropTypes.bool,
  isHeader: PropTypes.bool
};
DashNavItem.defaultProps = {
  link: "/", 
  icon: "", 
  title: "*PROP UNSET*", 
  // active: false,
  isHeader: false
};

