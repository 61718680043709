import React, { Component } from 'react'
import { API } from '../../services/api';
import store from '../../store/store';
import userSlice from '../../store/userSlice';
import { connect } from 'react-redux';

import './../../assets/css/dash/admin.css'
import LoadingPlaceholder from '../../componets/dashboard/loadingPlaceholder';

class AdminPage extends Component {
    getAllUsers() {
        console.log('Admin > Getting all users...');

        API.request('GET', '/users/all').then((response) => {
          console.log(response);
          if (!response.error) {
            store.dispatch(userSlice.actions.adminUpdateAllUsers(response))
        }
        }, (error) => {
          console.log("req err => ", error)
        })
    }
    eopchToTimestamp(epoch) {
      var date = new Date(epoch);
      
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      
      return (year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds);
    }
    deleteUser(uuid) {

      console.log(`Deleting user > ${uuid}`);

      API.request('DELETE', `/users/${uuid}`).then((response) => {
        console.log(response);
        if (!response.error) {

          if (response.message === 'deleted user') { // did it work?

            store.dispatch(userSlice.actions.createNotification( // notify user about deletion
              {
                title: "User Deleted",
                message: `Successfully deleted user: ${uuid}`,
              },))

            this.getAllUsers(); // refresh user list
          }
      }
      }, (error) => {
        console.log("req err => ", error)
      })


    }

    componentDidMount() {
        this.getAllUsers();

    }
  render() {
    return (
      <div className='dash-page narrow'>
        <h1 className='dash-header-line'>
            Administration
        </h1>
        {this.props.admin.allUsers.length > 0 ?
        <table className='dash-admin-table'>
            <thead className='dash-admin-table-header'>
                <th>UUID</th>
                <th>Username</th>
                <th>Creation Date</th>
                <th>Update Date</th>
                <th>Yeet user</th>
            </thead>
            <tbody className='dash-admin-table-body'>
            { 
                this.props.admin.allUsers.slice(0).reverse().map((user) => {

                    return  <tr>
                        <td>{user.uuid}</td>
                        <td>{user.username}</td>
                        <td>{this.eopchToTimestamp(user.created_at)}</td>
                        <td>{this.eopchToTimestamp(user.updated_at)}</td>
                        <td>
                          <button 
                          className='dash-admin-button' 
                          onClick={() => {
                            store.dispatch(userSlice.actions.createConfirmationPopup(
                              {
                                title: "Delete User?",
                                message: "Are you sure you want to delete this user?",
                                actions: [
                                  {
                                    name: "Yeet user",
                                    action: () => {
                                      console.log("User yeet confirmed...");
                                      this.deleteUser(user.uuid);
                                      
                                    },
                                    dispose: true, // delete dialog after
                                    important: true
                                  }
                                ],
                              },
                            ))

                          }}>
                            Delete User
                          </button>
                        </td>
                    </tr>
                }) 
              }   



            </tbody>
        </table>
          : <LoadingPlaceholder />
        }
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        admin: state.admin,
  
    };
  }
  
  export default connect(mapStateToProps)(AdminPage);