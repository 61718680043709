import React, { Component } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { API } from '../../services/api';


class Auth extends Component {

    render() {
        const onSubmit = (e) => {
            e.preventDefault();
            console.log("Form submit");

            if (document.getElementById('formButton').classList.contains('login')) {
                API.login(API.getFormData('login'))
            } else if (document.getElementById('formButton').classList.contains('register')) {
                API.register(API.getFormData('register'))
            }
        };
        return (
            <div className="auth-wrapper">

                <div className='auth-container'>

                    <div className='auth-content'>

                        <Link className='auth-header' to='/'>TEHBB.xyz</Link>

                        <p className='auth-form-error' id='auth-form-error'></p>
                        <form className='auth-form-container' 
                        onSubmit={onSubmit}
                        >
                            <Outlet />
                        </form>
                    </div>

                </div>
            </div>
        );
    }
}

export default Auth;