import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {

    user: {
        username:"Null",
        created_at:1650182705334,
        uuid:"Null",
        updated_at:1659068781757,
        is_admin:false,
        avatar:"https://cdn.discordapp.com/attachments/890282402008686622/970179037081268284/iQKW8GRjt7.jpg",
        banner: "https://cdn.discordapp.com/attachments/890282402008686622/957649612515188756/Discor_Banner_I1_6.gif",
        biography:""
    },
    loginHistory: [
      // {agent: 'FAKEKEKE', ip: 'xd1', _id: '6969696969'},
    ],
    admin: {
      allUsers: [
      //   {
      //     username:"Null",
      //     created_at:1650182705334,
      //     uuid:"Null",
      //     updated_at:1659068781757,
      //     is_admin:false,
      //     avatar:"https://cdn.discordapp.com/attachments/890282402008686622/970179037081268284/iQKW8GRjt7.jpg",
      //     banner: "https://cdn.discordapp.com/attachments/890282402008686622/957649612515188756/Discor_Banner_I1_6.gif",
      //     biography:""
      // }
      ],
    },
    popups: {
      confirmation: [
        // {
        //   title: "Confirmation",
        //   message: "Are you sure you want to leave? 1",
        //   actions: [
        //     {
        //       name: "Leave",
        //       action: () => console.log("Leave"),
        //       dispose: true // delete dialog after
        //     }
        //   ],
        // },
      ],
      notification: [
        // {
        //   title: "Confirmation",
        //   message: "Are you sure you want to leave? 1",
        // },
      ]
    }
  },
  reducers: {
    removeConfirmationPopup: (state, action) => {
      state.popups.confirmation.splice(action.payload, 1);
    },
    createConfirmationPopup: (state, action) => {
      state.popups.confirmation.push(action.payload); // just chuck it in there
    },
    removeNotification: (state, action) => {
      state.popups.notification.splice(action.payload, 1);
    },
    createNotification: (state, action) => {
      state.popups.notification.push(action.payload); // just chuck it in there
    },
    adminUpdateAllUsers: (state, action) => {
      console.log("Updating admin all users from backend...");
      state.admin.allUsers = action.payload; // just chuck it in there
    },
    updateUserdata: (state, action) => {
      console.log("Updating userdata from backend...");
      state.user = action.payload; // just chuck it in there
    },
    updateLoginHistory: (state, action) => {
      state.loginHistory = action.payload; // just chuck it in there
    },
    clearLoginHistory: (state, action) => {
      state.loginHistory = []; // empty it
    },
  },

})

export default userSlice

