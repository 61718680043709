import React, { Component } from 'react'

export default class DashCdn extends Component {
  render() {
    return (
        <div className='dash-page narrow'>

            <h1 className='dash-header-line'>
                Content Distribution Network
            </h1>
        </div>
    )
  }
}
