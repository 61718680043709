import { configureStore } from '@reduxjs/toolkit'
import userSlice from './userSlice'

// const reducer = combineReducers({
//   reducer: userSlice.reducer
// })

const store = configureStore({
  reducer: userSlice.reducer
})


export default store

