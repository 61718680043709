import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Register extends Component {

    render() {

        return (

            <div name="register">
                {/* <input type="text" id="auth-form-invite" className='auth-input' placeholder='Invite code'/> */}
                <input type="text" id="auth-form-username" className='auth-input' placeholder='Username'/>
                <input type="text" id="auth-form-email" className='auth-input' placeholder='Email'/>
                <input type="text" id="auth-form-password" className='auth-input' placeholder='Password'/>
                <button type="submit" className='auth-input btn register' id='formButton'>Register</button>
                <br />
                <Link to='/auth/login' className='auth-text-link'>Login</Link>

            </div>

        );
    }
}

export default Register;