import store from "../store/store";
import userSlice from "../store/userSlice";

// export const BACKEND_HOST =  "https://tehbb.xyz:6969";
export const BACKEND_HOST = (process.env.NODE_ENV === "production") ? "https://tehbb.xyz:6969" : "http://localhost:6969";

var API = {
    getFormData: function(formType) {
        var formData;
        if (formType === "login") { // kina sketch but idk where else to put this:
            formData = {
                username: `${document.getElementById('auth-form-username').value}`,
                password: `${document.getElementById('auth-form-password').value}`,
            }
        }
        if (formType === 'register') { // add extra register fields
            console.log("Registration data");
            formData = {
                username: `${document.getElementById('auth-form-username').value}`,
                password: `${document.getElementById('auth-form-password').value}`,
                email:    `${document.getElementById('auth-form-email').value}`,
                // invite:   `${document.getElementById('auth-form-invite').value}`
            }
        }

        return formData;
    },
    login: async function (data) {
        await API.request('POST', '/users/@login', data).then((response) => {
            console.log(response);
            if (response.token !== undefined) { // if recived a token
                document.getElementById('auth-form-error').innerHTML = "Logging in...";
                localStorage.setItem("token", response.token); // store token in localStorage
                store.dispatch(userSlice.actions.updateUserdata(response.user));

                // TODO: to better redirection <authroutes>??
                window.location.href = "/#/dashboard" // slap the user at the dash

            } else { // error
                document.getElementById('auth-form-error').innerHTML = response.error;
            }
        }, (error) => {
            document.getElementById('auth-form-error').innerHTML = "Error sending request, please try again later.";
        });
    },
    register: async function (data) {
        console.log(data);
        await API.request('POST', '/users/@register', data).then((response) => {
            console.log(response);
            if (response.token !== undefined) { // if recived a token
                document.getElementById('auth-form-error').innerHTML = "Registering user...";
                localStorage.setItem("token", response.token); // store token in localStorage

                // TODO: to better redirection <authroutes>??   
                window.location.href = "/#/auth/login"; // slap the user at the login page

            } else { // error
                document.getElementById('auth-form-error').innerHTML = response.error.message;
            }
        }, (error) => {
            document.getElementById('auth-form-error').innerHTML = "Error sending request, please try again later.";
        });
    },
    request: async function(
		method="GET",
		endpoint="/",
		body,
	) {
        return await new Promise((resolve, reject) => {

            let token = localStorage.getItem("token") || "unset";

            let headers = {
                "Authorization": `${token}`,
                "Content-Type": "application/json",
                "cf-connecting-ip": "INDEV",
            };
            console.log(`Headers>`);
            console.log(headers); 
            console.log(`token : ${token}`);
            fetch(BACKEND_HOST + endpoint, {
                method: method, 
                headers: headers,
                body: JSON.stringify(body),
            })
            .then((response) => response.json())
            .then((response) => {
                console.log('Success:', response);
                resolve(response)
            })
            .catch((error) => {
                console.error('Error:', error);
                reject(error)

            });
            // }).catch((err) => reject(err));

        });
	}
}
export {API}