import React, { Component } from 'react';

import "./../../../assets/css/main.css"


export default class DashSettingsOption extends Component {

    constructor(props) {
        super(props);
        this.state = {value: this.props.defaultValue ? this.props.defaultValue : 0};
    }
    
    updateLabel = (value) => {
        this.setState({value: value});
    }
    
  render() {
    return (
      <div className='dash-settings-option'>
        <div className={`dash-settings-option-left ${this.props.largerInput ? 'large-input' : ''}`}>
            <h1 className='dash-settings-option-title'>{this.props.title}</h1>
            <br />
            <p className='dash-settings-option-description'>{this.props.description}</p>
        </div>
        <div className={`dash-settings-option-right ${this.props.largerInput ? 'large-input' : ''}`}>
            <div className='dash-settings-option-input-container'>
                {this.props.type === 'range' ?
                    <input 
                    className='dash-settings-option-input-range' 
                    value={this.props.value} 
                    type='range' 
                    onChange={(event) => {
                        this.updateLabel(event.target.value)
                        this.props.onChange(event)
                    }}
                    defaultValue={this.props.defaultValue}
                    />
                : ''}

                {this.props.type === 'number' ?
                    <input 
                    className='dash-settings-option-input-number' 
                    value={this.props.value} 
                    type='number' 
                    onChange={this.props.onChange}
                    defaultValue={this.props.defaultValue}
                    />
                : ''}

                {this.props.type === 'text' ?
                    <input 
                    className='dash-settings-option-input-text' 
                    value={this.props.value} 
                    placeholder={this.props.placeholder} 
                    type='text' 
                    defaultValue={this.props.defaultValue} 
                    onChange={this.props.onChange}/>
                : ''}

                {this.props.type === 'drop' ?
                    <select className='dash-settings-option-input-drop' defaultValue={this.props.defaultValue} onChange={this.props.onChange}>
                        {this.props.options.map(option =>
                            <option key={option.value} value={option.value}>{option.label}</option>
                        )}
                    </select>
                : ''}

                {this.props.type === 'button' ?
                <button className='dash-settings-option-input-button' onClick={this.props.onClick}>{this.props.label}</button>
                : ''}

                {this.props.type === 'switch' ?
                <div><label class="switch"><input type="checkbox" defaultChecked={this.props.defaultValue} /><span class="slider round"></span></label></div>
                : ''}

                {this.props.type === 'range' ? // is a label needed aswell?
                    <p id='dash-settings-option-input-label' className='dash-settings-option-input-label'>{this.state.value}</p>
                : ''}

                
            </div>
            
        </div>

        {this.props.underneath ? <div className='dash-settings-option-underneath-wrapper'>
            <div className='dash-settings-option-underneath'>
                {this.props.underneath}
            </div>
        </div> : ""}
        

      </div>
    );
  }
}
