import React, { Component } from 'react'
import { ClientManager } from '../../assets/js/clientSettings';

export default class SiteBackground extends Component {
  Client = new ClientManager();

  componentDidMount() {
    console.log("BG > Reloaded appearance settings");
    this.Client.settings.reload.appearance();
  }

  render() {
    return (
      
      <div className="background-wrapper">
        <div className="background-img"></div>
        {/* <video className="background-video" autoPlay onLoadStart="this.volume=0.5"
          src="/assets/vid/Motion.mp4"></video> */}
        <div className="background-shade"></div>
      </div>
    )
  }
}
