import React, {
    Component
} from 'react'
import { connect } from 'react-redux';
import {
    Outlet
} from 'react-router-dom'
import NoticePage from '../../../views/noticePage';

class AdminMount extends Component {
    componentDidMount() {

    }

    render() {
        return ( 
        <div>
            {
                this.props.user.is_admin ?
                    <Outlet />
                :
                    <NoticePage title={'No Authorisation'} desc={'You cannot be here.'} redirect={true}/>
            }
        </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
  
    };
  }
  
  export default connect(mapStateToProps)(AdminMount);