import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Login extends Component {
    componentDidMount() {
        document.getElementById('auth-form-username').select();
    }
    render() {
        return (
            <div>

                <input type="text" id="auth-form-username" className='auth-input' placeholder='Username'/>
                <input type="password" id="auth-form-password" className='auth-input' placeholder='Password'/>
                <button type="submit" className='auth-input btn login' id='formButton'>Login</button>
                <br />
                <Link to='/auth/register' className='auth-text-link'>Register</Link>

            </div>
        );
    }
}

export default Login;