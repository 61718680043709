import React, { Component } from 'react'
import { ClientManager } from '../../../assets/js/clientSettings';
import store from '../../../store/store';
import userSlice from '../../../store/userSlice';
import DashSettingsOption from './settingOption'

export default class AccountSettings extends Component {

  Client = new ClientManager();

  render() {
    return (
      <div className="dash-settings-tab-panel">
        
        <h1 className='dash-settings-header'>Account</h1>
                        
                        <DashSettingsOption 
                            title='Logout'
                            description='Clears token from browsers localStorage'
                            type={'button'}
                            label={"Logout"}
                            onClick = {
                              () => {
                                store.dispatch(userSlice.actions.createConfirmationPopup({
                                  title: "Logout?",
                                  message: "Are you sure you want to logout?",
                                  actions: [{
                                    name: "Logout",
                                    action: () => {
                                      console.log("Logout confirmed...");
                                      this.Client.logout();
                                    },
                                    dispose: true, // delete dialog after
                                    important: true
                                  }],
                                }, ))
                              }
                            }
                        />

<DashSettingsOption 
                            title='Clear Browser Storage'
                            description='Clears all settings in browsers localStorage, this will log you out (Can fix some bugged clients)'
                            type={'button'}
                            label={"Clear localStorage"}
                            onClick = {
                              () => {
                                store.dispatch(userSlice.actions.createConfirmationPopup({
                                  title: "Clear storage?",
                                  message: "Are you sure you want to clear browser storage?",
                                  actions: [{
                                    name: "Clear",
                                    action: () => {
                                      console.log("Clear storage confirmed...");
                                      this.Client.settings.clearStorage()
                                    },
                                    dispose: true, // delete dialog after
                                    important: true
                                  }],
                                }, ))
                              }
                            }
                        />

                        
                        <DashSettingsOption 
                            title='Generate CDN Token'
                            description='Generate at token to use the CDN'
                            type={'button'}
                            label={"Generate"}
                            onClick = {
                              () => {
                                this.Client.generate_cdn_token().then((token) => {
                                  console.log(token)
                                  store.dispatch(userSlice.actions.createConfirmationPopup({
                                    title: "Token",
                                    message: `Your CDN token: ${token.token}`,
                                    actions: [],
                                  }))
                                })
                              }
                            }
                        />

                        <DashSettingsOption 
                            title='Delete Account'
                            description='Your account? Yeeted into the abyss!'
                            type={'button'}
                            label={"Not coded yet lol"}
                            onClick = {
                              () => {
                                store.dispatch(userSlice.actions.createConfirmationPopup({
                                  title: "Delete Account?",
                                  message: "Are you sure you want to delete your account?",
                                  actions: [{
                                    name: "Yeet",
                                    action: () => {
                                      console.log("User yeet confirmed...");

                                    },
                                    dispose: true, // delete dialog after
                                    important: true
                                  }],
                                }, ))
                              }
                            }
                        />
      </div>
    )
  }
}
