import React, { Component } from 'react'
import { Outlet } from 'react-router-dom';
import { ClientManager } from '../../assets/js/clientSettings';
import { DashManager } from '../../services/dashboard'
import DashNav from '../../componets/dashboard/dashNav';
import Window from '../../componets/dashboard/window';
import Notification from '../../componets/dashboard/notification';

import './../../assets/css/dashboard.css';

export default class Dashboard extends Component {

    dashboard = new DashManager();
    Client = new ClientManager();
    componentDidMount() {



        console.log("Dash > Reloaded appearance settings");
        this.Client.settings.reload.appearance(); // reload the appearance settings
        this.Client.settings.reload.userdata(); // reload user data
        
    }
    render() {

        return (
            <div className='dash-wrapper'>
                <Window />
                <Notification />
                <DashNav />
                <div className='dash-content-container'>
                    <Outlet />
                </div>
            </div>
        )
    }
}
