import PropTypes, { number } from 'prop-types'
import React, { Component } from 'react'

export default class Timestamp extends Component {
  static propTypes = {epoch: number}
    formatTimestamp(epoch) {
        let date = new Date(parseInt(epoch, 10));
        return date.toLocaleString()
    }
  render() {
    return (
      <div>
        {this.formatTimestamp(this.props.epoch)}
      </div>
    )
  }
}
