import React, { Component } from 'react';
import {
} from "react-router-dom";


import './../assets/css/errorPage.css'
export default class NoticePage extends Component {
  render() {
    return (
      <div className='errorpage-content-container'>
        <h1 className='errorpage-header'>{this.props.title}</h1>
        <p className='errorpage-description'>{this.props.desc}</p>
        {
            this.props.redirect ? 
            <p className='errorpage-description'>Redirecting to / in 3 seconds...
            <meta http-equiv="refresh" content="3; URL=/" />
            </p>
            
            : 
            <a className='errorpage-description' href='/'>Back to sanity</a>
        }
        
        <div className='errorpage-image'></div>
    
      </div>
    );
  }
}
