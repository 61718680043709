import React, { Component } from 'react'
import './../../../assets/css/dash/profile.css'

export default class UserBasicProfile extends Component {
  render() {
    return (
        <div
            className={`dash-profile-basic-wrapper ${this.props.flatTop ? 'flat-top' : ''}`}
            style={
                {backgroundImage: `url(${this.props.backgroundImage ? this.props.backgroundImage : ''})`}
            }
        >
            <div className='dash-profile-basic-inner'>
                <div 
                    className='dash-profile-avatar'
                    style={
                        {backgroundImage: `url(${this.props.user.avatar ? this.props.user.avatar : ''})`}
                    }
                />
                <div className='dash-profile-basic-text'>
                    <h1 className='dash-profile-username'>{this.props.user.username ? this.props.user.username : 'No Username??'}</h1>
                    <br />
                    <br />
                    <h1 className='dash-profile-uuid'>{this.props.user.uuid ? this.props.user.uuid : ''}</h1>
                </div>
                {/* <div className='dash-profile-basic-bio-container'>
                    <textarea className='dash-profile-basic-bio'>
                    </textarea>
                </div> */}
            </div>
            
        </div>
    )
  }
}
