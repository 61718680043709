import React, { Component } from 'react'
import { connect } from 'react-redux';

import './../../assets/css/dashboard.css';
import './../../assets/css/dash/window.css';
import store from '../../store/store';
import userSlice from '../../store/userSlice';

class Window extends Component {



  componentDidMount() {

  }

  deleteMsgSelf(index) {
    store.dispatch(userSlice.actions.removeConfirmationPopup(index))

  }


  render() {
    return (
      <div className={`
        dash-window-wrapper 
        ${this.props.popups.confirmation.length === 0 ? 'hidden' : ''}
      `}>
        <div className='dash-window-container'>
        {
          this.props.popups.confirmation.slice(0).map((message, index) => {

            return <div className='dash-window'>
                <h1 className='dash-window-header'>
                  {message.title}
                </h1>
                <p className='dash-window-message'>
                  {message.message}
                </p>

                <div className='dash-window-input-container'>
                  <button 
                    className={`dash-window-input-button closes`}
                    onClick={
                      // self die moment
                      () => {
                        this.deleteMsgSelf();
                      }
                    }
                  >
                    Close
                  </button>
                  {
                    message.actions.slice(0).reverse().map((action) => {

                      return <button 
                        className={`dash-window-input-button right ${action.important ? 'important' : ''}`}
                        onClick={() => {
                          action.action();
                          if (action.dispose === true) { // destroy message after?
                            this.deleteMsgSelf();
                          }
                        }}
                      >
                        {action.name}
                        </button>
                    })
                  }
                </div>
              </div>
          })
          
        }   
        </div>


      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
      popups: state.popups,

  };
}

export default connect(mapStateToProps)(Window);