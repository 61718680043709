import React, { Component } from 'react'
// import TypewriterComponent from 'typewriter-effect'
import NavBar from '../../componets/site/topNavBar'
// import { runTyper } from '../../services/typer'
import Typewriter from "typewriter-effect";

import './../../assets/css/global.css'
import './../../assets/css/landing.css'
import { ClientManager } from '../../assets/js/clientSettings';
export default class LandingPage extends Component {
  Client = new ClientManager();

  componentDidMount() {
    console.log("Landing > Reloaded appearance settings");
    this.Client.settings.reload.appearance();

  }
  render() {
    return (
      <div>

        <NavBar />

        <div className='landing-wrapper'>
            <div className='landing-header-container'>
                <h1 className='landing-header-main'>TEHBB<span className='landing-header-tld'>.XYZ</span></h1>
                <div className='landing-header-seperator'/>
                <p className='landing-header-sub'>
                <Typewriter
                options={{
                    delay: 100,
                    deleteSpeed: 0,
                }}
                onInit={(typewriter) => {

                typewriter
                
                // .typeString("Hello there...")                    
                // .pauseFor(1000)
                // .deleteAll()
                .pauseFor(1600)

                .typeString("Welcome to TEHBB.xyz!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("Never go full retard<br>")
                .typeString("-Sun Tzu")
                .start();
                }}
                />
                </p>
            </div>
        </div>
      </div>
    )
  }
}
